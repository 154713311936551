


<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 86vh"
      v-if="loading"
    ></BaseLoading>
    <div v-else>
      <BaseHeader :title="'Messages'"></BaseHeader>

      <div class="row mt-2">
        <div class="col-sm-3">
          <div class="card">
            <div class="card-header bg-transparent header-elements-inline">
              <span class="text-uppercase font-size-sm font-weight-semibold"
                >Order Messages</span
              >
              <!-- <div class="header-elements">
                <span class="badge bg-success badge-pill">+32</span>
              </div> -->
            </div>

            <ul class="media-list media-list-linked my-2">
              <li class="media text-muted border-0 py-2">
                Select order to start chat
              </li>

              <li
                v-for="(msg, index) in allMessages"
                :key="index + 'd'"
                @click="getMessages(msg.order_id)"
                :class="msg.order_id === order.id ? 'bg-primary-400' : ''"
              >
                <a class="media">
                  <div class="mr-3">
                    <img
                      :src="`/${process.env.VUE_APP_INSTALL_DIR}/img/image.58f01911.png`"
                      class="rounded-circle"
                      alt=""
                      width="40"
                      height="40"
                    />
                  </div>
                  <div class="media-body">
                    <div class="media-title font-weight-semibold">
                      #{{ msg.order_id || "" }}
                    </div>
                    <span class="badge badge-pill bg-teal" v-if="msg.unread > 0"
                      >Unread {{ msg.unread || 0 }}</span
                    >
                  </div>
                  <div class="align-self-center ml-3">
                    <span
                      class="badge badge-mark bg-success border-success"
                    ></span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- ./col-sm-3 -->
        <div class="col-12 col-sm-9">
          <Chat :order="order" class="card" />
        </div>
        <!-- ./col-sm-9 -->
      </div>
    </div>
  </div>
</template>

<script>
import Chat from "../../components/order/order-message/Chat.vue";
// import Summary from "../../components/order/order-message/Summary.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "OrderMessages",
  components: {
    Chat,
    // Summary
  },
  computed: {
    ...mapState("messages", ["allMessages", "loading"]),
    ...mapState("order", ["order"]),
  },
  mounted() {
    this._getAllMessages();
  },
  methods: {
    ...mapActions("messages", ["_getAllMessages", "_getMessages"]),
    ...mapActions("order", ["_getOrder"]),
    getMessages(order_id) {
      this.loading = true;
      this._getMessages(order_id)
        .then(() => this._getOrder(order_id))
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>


<style lang="scss" scoped>
.is-messages {
  // max-height: 70% !important;
  max-height: 88vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.is-listing {
  cursor: pointer;
  &:hover {
    background-color: #e5f8e6;
    // box-shadow: 0 2px 5px #ccc;
    border: 0;
    transition: 0.2s ease-in-out;
  }
}
.inbox-listing {
  overflow-y: scroll;
  
  .is-active {
    background-color: #e5f8e6;
    // box-shadow: 0 2px 5px #e5f8e6;
  }
}

@media screen and (min-width: 768px) {
  .inbox-listing {
    max-height: 82vh;
    min-height: 80vh;
  }
}
</style>
