<template>
  <div ref="inout-section">
    <BaseLoading style="min-height: 400px" v-if="loading_mgs"></BaseLoading>
    <div v-else>
      <div class="" v-if="order && Object.keys(order).length > 0">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">
            Order ID:
            <router-link :to="{ name: 'Order', params: { id: order.id } }"
              >#{{ order.id || "" }}</router-link
            >
          </h5>
          <div class="header-elements">
            <div class="list-icons">
              <!-- <a class="list-icons-item" data-action="reload"></a> -->
            </div>
          </div>
        </div>
        <div class="card-body px-0">
          <ul class="media-list media-chat media-chat-scrollable mb-0 px-1">
            <span v-if="orderMessages && orderMessages.length > 0">
              <span
                v-for="(message, index) in orderMessages"
                :key="index + 'msgs'"
              >
                <li v-if="!parseInt(message.is_sender)" class="media">
                  <div class="mr-3">
                    <a :href="`/${install_dir}/img/image.58f01911.png`">
                      <img
                        :src="`/${install_dir}/img/image.58f01911.png`"
                        class="rounded-circle"
                        alt=""
                        width="40"
                        height="40"
                      />
                    </a>
                  </div>

                  <div class="media-body mb-2">
                    <h5 class="font-weight-bold mb-0">
                      {{ message.user_type || "" }}
                    </h5>
                    <div class="media-chat-item p-0">
                      <span v-html="message.message || ''"></span>
                    </div>
                    <div class="font-size-sm text-muted mb-2">
                      {{ message.create_stamp || "" }}
                    </div>
                  </div>
                </li>

                <li v-else class="media media-chat-item-reverse">
                  <div class="media-body">
                    <div class="media-chat-item p-0">
                      <span v-html="message.message || ''"></span>
                    </div>
                    <div class="font-size-sm text-muted mb-2">
                      {{ message.create_stamp || "" }}
                    </div>
                  </div>

                  <div class="ml-3">
                    <a>
                      <img
                        :src="`${require('../../../assets/global_assets/images/image.png')}`"
                        class="rounded-circle"
                        alt=""
                        width="40"
                        height="40"
                      />
                    </a>
                  </div>
                </li>
              </span>
            </span>
            <!-- notes -->
            <div class="alert alert-success border-0 alert-dismissible mb-1">
              <button type="button" class="close" data-dismiss="alert">
                <span>×</span>
              </button>
              <span class="font-weight-bold">Note!</span> Simply Type in your
              message and click on send. The message will be received by the
              assigned writer and support team.
              <a href="#" class="alert-link">try submitting again</a>.
            </div>
            <div class="alert alert-danger border-0 alert-dismissible">
              <button type="button" class="close" data-dismiss="alert">
                <span>×</span>
              </button>
              Your personal information (Phone Number, Email Address) is always
              kept private and we won't share it–just don't include it in your
              order details.
            </div>

            <!-- /notes -->
          </ul>

          <form @submit.prevent="sendMessage" class="px-2">
            <textarea
              name="enter-message"
              class="form-control mb-3"
              rows="3"
              cols="1"
              v-model="messageForm.message"
              required
              placeholder="Enter your message..."
            ></textarea>

            <div class="d-flex align-items-center">
              <div class="form-check">
                <input
                  v-model="messageForm.receiver_id"
                  value="-1"
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  checked
                />
                <label class="form-check-label" for="exampleRadios1">
                  Support
                </label>
              </div>
              <div class="mx-2"></div>
              <div class="form-check" v-if="order.writer_id">
                <input
                  v-model="messageForm.receiver_id"
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  :value="order.writer_id"
                />
                <label class="form-check-label" for="exampleRadios2">
                  Writer
                </label>
              </div>

              <button
                type="submit"
                class="btn btn-primary btn-labeled btn-labeled-right mr-auto"
                :disabled="messageForm.busy"
              >
                <b><i class="icon-paperplane"></i></b> Send
              </button>
            </div>
          </form>
        </div>
      </div>

      <div v-else class="card-body">
        <h5 class="py-4 mx-2">Select a conversation to start chatting</h5>
      </div>
    </div>
    <BaseOverlay v-if="sending"></BaseOverlay>
  </div>
</template>


<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chatHeight: 0,
      loading_mgs: false,
      sending: false,
      env: "",
    };
  },
  computed: {
    ...mapState("messages", ["orderMessages", "messageForm"]),
    // ...mapState("order", ["order"])
  },
  async mounted() {
    this.install_dir = process.env.VUE_APP_INSTALL_DIR;
    this.loading_mgs = true;
    await this.getMessages()
      .then((res) => {
        console.log("Messages res ", res);
        this.loading_mgs = false;
      })
      .catch((err) => {
        console.log("Messages err ", err);
        this.loading_mgs = false;
      })
      .finally(() => {
        this.loading_mgs = false;
      });
  },

  methods: {
    ...mapActions("messages", ["_getMessages", "_sendMessage"]),
    ...mapMutations("messages", ["setMessages"]),

    scrollToBottom() {
      var element = this.$refs["inout-section"];
      element.scrollTop = element.scrollHeight;
      console.log("this.chatHeight", element.scrollHeight);
    },

    async getMessages() {
      try {
        console.log("this.$route.params.id", this.$route.params.id);
        await this._getMessages(this.$route.params.id || this.order.id);
      } catch (error) {
        this.loading_mgs = false;
      }
    },
    sendMessage() {
      this.sending = true;
      this._sendMessage({
        messageForm: this.messageForm,
        order_id: this.order.id,
      })
        .then((res) => {
          console.log(res);
          this.messageForm.reset();
          // success
        })
        .catch((err) => {
          console.log(err);
          // handle error
        })
        .finally(() => {
          this.sending = false;
          this.scrollToBottom();
        });
    },
  },
  beforeDestroy() {
    this.setMessages([]);
  },
};
</script>


<style lang="scss" scoped>
// .alert{
//   border-radius: 4px !important;
// }

@media screen and (min-width: 800px) {
  .is-placeholder {
    min-height: 140px;
  }
  .is-chat-section {
    min-height: 7 8vh !important;
  }

  .stick-message-form {
    position: sticky;
    bottom: 5px;
  }

  .fix-message-form {
    margin-top: 20px;
    position: absolute;
    bottom: -10px;
    left: 20px;
    right: 34px;
  }
}
.is-chat-section {
  max-height: 78vh;
  min-height: 40vh;
  overflow-y: scroll;

  .media {
    width: 80%;

    &.is-out {
      .media-body {
        background: #dcf8c6;
      }
    }
    .media-body {
      box-shadow: 0 2px 5px #ccc;
    }
  }
}

@media screen and (min-width: 800px) {
  .is-chat-section {
    min-height: 85vh !important;
  }
  .stick-message-form {
    position: sticky;
    bottom: 5px;
    // position: absolute;
    // bottom: -10px;
    margin-bottom: 20px;
    // left: 10px;
    // right: 30px;
  }
}
</style>